import React from "react"
import Layout from "../../components/layout-team-access"
import styles from "../../components/team-documents.module.scss"
import agreement from "../../static/RF-Team-Agreement-32819.pdf"
import autopay from "../../static/RF-Auto-Pay-32819.pdf"

class TeamDocuments extends React.Component {
  constructor(props) {
    super (props)

    this.state = {
      data: []
    }
  }
  async componentDidMount() {
    try {
      const response = await fetch(`https://rfdance.com/app/wp-json/wp/v2/posts?categories=3`)
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      this.setState({ data: json })
      console.log('response', json)
    } catch (error) {
      console.log('error', error)
    }
  }

  render() {
    const getDocs = () => {
      return Array.from(this.state.data, (post, index) => {
        let content = post.content.rendered.split("src=\"")
        content = content[1].split("\"")
        let url = content[0]
        return (<p key={index}><a target="_blank" rel="noopener noreferrer" href={url} className="button is-info">{post.title.rendered}</a></p>)
      })
    }

    return (
      <Layout>
        <h1 style={({'textAlign':`center`,'margin':`1em auto 2em`})}>Team Documents</h1>
        <div className={`${styles.container} container`}>
          <div className={styles.pdf}>
            {getDocs()}
          </div>
          <div className={styles.pdf}>
            <h2>PDFs</h2>
            <p><a target="_blank" rel="noopener noreferrer" href={agreement} className="button is-info">RF Team Agreement (updated 3/28/19)</a></p>
            <p><a target="_blank" rel="noopener noreferrer" href={autopay} className="button is-info">RF Auto Pay (updated 3/28/19)</a></p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TeamDocuments